import { ClientConfig } from "@searchspring/snap-client";
import { StoreRegion } from "../builder/builder.config";

// export type StoreSearchSpringConfig = {
// 	[store: StoreRegion]: ClientGlobals;
// };

export type StoreSearchSpringConfig = typeof productionStores;

export type StoreKey = keyof typeof productionStores;

// Production and Sandbox store configurations
const productionStores = {
	eu: {
		siteId: "drrs6p",
	},
	us: {
		siteId: "66trg1",
	},
	ca: {
		siteId: "21ljcv",
	},
	global: {
		siteId: "",
	},
} as const;
// NOTE this is a development environment that needs to use production configs
const sandboxStores = {
	eu: {
		siteId: "drrs6p",
	},
	us: {
		siteId: "66trg1",
	},
	ca: {
		siteId: "21ljcv",
	},
	global: {
		siteId: "",
	},
} as const;

const clientConfig = {
	mode:
		(process.env.NEXT_PUBLIC_APP_ENV || "development") === "production"
			? "production"
			: "development",
} as ClientConfig;

export const getConfig = (store: StoreRegion) => {
	return {
		globals: getStoreGlobals(store),
		clientConfig,
	};
};

export const getStoreGlobals = (region: StoreRegion) => {
	const stores =
		clientConfig.mode === "production" ? productionStores : sandboxStores;
	return stores[region];
};
