"use client";

import useStoreKeys from "@/hooks/useStoreKeys";
import { Cart, ProductData } from "@/lib/5874/types";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";
import { getProductsWithoutChildrenByIds } from "@/lib/bigcommerce/product";
import {
	deleteClientCookie,
	getCartIdCookie,
	setClientCartCookie,
} from "@/utils/client-cookie-utils";
import {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import useIntelliSuggestTracking from "@/hooks/useIntelliSuggestTracking";
import { DataLayerProductData } from "@/datalayer/DataLayerContextProvider";

interface ContextProps {
	cart: Cart | undefined;
	setCart: (cart: Cart | undefined) => void;

	isModifyingCart: boolean;
	setIsModifyingCart: (isModifyingCart: boolean) => void;

	confirmationProduct:
		| ProductData
		| { id: number; quantity: number }
		| undefined;
	setConfirmationProduct: (
		confirmationProduct: ProductData | undefined,
	) => void;
	totalItems: number;
	cartProducts: ProductData[] | undefined;
	datalayerProducts: DataLayerProductData[] | undefined;
}

interface Props {
	initialCart: Cart | undefined;
	deleteCookie: boolean;
	children: React.ReactNode;
}

const Context = createContext({} as ContextProps);

export const CartContextProvider = ({
	initialCart,
	deleteCookie,
	children,
}: Props) => {
	const { store } = useStoreKeys();
	const { session } = useSessionContext();
	const intelliSuggest = useIntelliSuggestTracking();

	const [cart, setCart] = useState<Cart | undefined>(initialCart);
	const [confirmationProduct, setConfirmationProduct] = useState<
		ProductData | undefined
	>(undefined);
	const [isModifyingCart, setIsModifyingCart] = useState(false);
	const [cartProducts, setCartProducts] = useState<
		Array<ProductData> | undefined
	>();
	const [datalayerProducts, setDatalayerProducts] = useState<
		Array<DataLayerProductData> | undefined
	>();

	useEffect(() => {
		if (deleteCookie) {
			deleteClientCookie("cartId");
		}
	}, []);

	useEffect(() => {
		if (session === null && cart !== undefined) {
			setClientCartCookie(cart.id, store, 0);
			setCart(undefined);
		}

		// check to see if customerId in cookie matches session
		if (session !== null && cart === undefined) {
			const cookie = getCartIdCookie();

			if (
				cookie &&
				(cookie.customerId !== session?.customerId || cookie.store !== store)
			) {
				deleteClientCookie("cartId");
			}
		}
	}, [session]);

	useEffect(() => {
		if (!cart) {
			setCartProducts([]);
			return;
		}

		setClientCartCookie(cart.id, store, session?.customerId ?? 0);

		const newEntityIds =
			cart?.line_items.physical_items.map((i) => i.product_id) || [];

		// Memoize the previous entity IDs using useRef
		const previousEntityIds = entityIdsRef.current;
		entityIdsRef.current = newEntityIds;

		// Compare new and previous entity IDs
		const entityIdsChanged =
			!previousEntityIds ||
			newEntityIds.length !== previousEntityIds.length ||
			!newEntityIds.every((id, index) => id === previousEntityIds[index]);

		if (entityIdsChanged) {
			if (newEntityIds.length === 0) {
				setCartProducts([]);
				return;
			}

			const lineItemMap = new Map(
				cart.line_items.physical_items.map((item) => [
					item.product_id,
					item.quantity,
				]),
			);

			getProductsWithoutChildrenByIds(newEntityIds)
				.then((data) => {
					if (data) {
						setCartProducts(data);
						const dlpsArray: DataLayerProductData[] = data.map((product) => ({
							product,
							quantity: lineItemMap.get(product.id) ?? 1,
							variantId: product.productId,
						}));
						setDatalayerProducts(dlpsArray);
					} else {
						setCartProducts([]);
					}
				})
				.catch((err) => console.error(err));
		}
	}, [cart, getProductsWithoutChildrenByIds, store, session?.customerId]);

	const entityIdsRef = useRef<number[]>([]);

	const totalItems = useMemo(() => {
		if (!cart) {
			return 0;
		}

		return cart.line_items.physical_items.reduce(
			(acc: number, item) => acc + item.quantity,
			0,
		);
	}, [cart]);

	const setCartWithTracker = (cart: Cart | undefined) => {
		setCart(cart);

		if (!cart) {
			return;
		}

		intelliSuggest.basketContents(cart.line_items.physical_items);
	};

	const value = {
		cart,
		totalItems,
		setCart: setCartWithTracker,
		cartProducts,
		isModifyingCart,
		setIsModifyingCart,
		confirmationProduct,
		setConfirmationProduct,
		datalayerProducts,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCartContext = () => {
	const context = useContext(Context);

	if (!context) {
		throw new Error("useCartContext must be used within a CartContextProvider");
	}

	return context;
};
