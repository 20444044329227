import { CartPhysicalItem, ProductData } from "@/lib/5874/types";
import { getConfig } from "@/lib/searchspring/searchspring.config";
import {
	ProductData as SearchSpringProductData,
	Tracker,
} from "@searchspring/snap-tracker";
import useStoreKeys from "./useStoreKeys";

const useIntelliSuggestTracking = () => {
	const { store } = useStoreKeys();
	const config = getConfig(store);

	const intellisuggestTrackBasketContents = (
		products: CartPhysicalItem[] | undefined,
	) => {
		const tracker = new Tracker(config.globals);

		tracker.track.cart.view({
			items:
				products?.map((product) => {
					return {
						uid: String(product.id),
						sku: product.sku,
						qty: String(product.quantity),
						price: String(product.list_price),
					} as SearchSpringProductData;
				}) || [],
		});
	};

	const intellisuggestTrackProductClick = (product: ProductData) => {
		const tracker = new Tracker(config.globals);

		tracker.track.product.click({
			intellisuggestData: product.intellisuggestData || "",
			intellisuggestSignature: product.intellisuggestSignature || "",
			href: product.path,
		});
	};

	return {
		productClick: intellisuggestTrackProductClick,
		basketContents: intellisuggestTrackBasketContents,
	};
};

export default useIntelliSuggestTracking;
